'use client'

import cn from 'clsx'
import { DefaultTFuncReturn } from 'i18next'
import { FC, Fragment, memo } from 'react'
import {
  SearchAutocompletePhrases,
  SearchAutocompleteProducts,
} from '@components/common'
import { ChevronLeft, CloseIcon, SearchSmIcon } from '@components/icons'
import { Button } from '@components/ui'
import Link from '@components/ui/Link'
import { useAutocompleteSearch } from '@lib/hooks/useAutocompleteSearch'
import buildSearchUrl from '@lib/utils/url/buildSearchUrl'
import { useClientTranslation } from '@app/i18n/client'

interface Props {
  className?: string
  placeHolder?: string | DefaultTFuncReturn
  id?: string
  isMobile?: boolean
}

const Searchbar: FC<Props> = ({ className, placeHolder, isMobile }) => {
  const {
    inputValue,
    setInputValue,
    handleInputChange,
    handleSearch,
    phrases,
    products,
    totalProducts,
    ref,
    handleClose,
  } = useAutocompleteSearch()

  const { t } = useClientTranslation(['common', 'search'])
  const twoColumnLayout = !!products?.length && !!phrases?.length

  return (
    <div
      ref={ref}
      className="fixed flex max-h-[calc(100%-97px)] w-full flex-col bg-white max-md:top-[97px] md:relative"
    >
      <div className="flex w-full items-center bg-white max-lg:shadow-xl">
        <form
          onSubmit={handleSearch}
          className={cn('relative flex-1', className)}
        >
          <Fragment>
            <input
              type="text"
              placeholder={
                placeHolder ?? t('Search', { ns: 'search' }) ?? 'Search'
              }
              data-testid="searchbar"
              onChange={(e) => handleInputChange(e.target.value)}
              value={inputValue ?? ''}
              className="relative !pr-8"
            />
            <button
              type="submit"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <SearchSmIcon />
            </button>
          </Fragment>
        </form>
        {isMobile && (
          <Button
            variant="ghost"
            onClick={handleClose}
            className="m-4 block !w-auto lg:hidden"
          >
            <CloseIcon />
          </Button>
        )}
      </div>
      {inputValue?.length > 1 && (
        <div
          className={cn(
            'grid-rows-auto grid w-full overflow-y-auto bg-white lg:absolute lg:inset-x-1/2 lg:top-[53px] lg:h-auto lg:-translate-x-1/2 lg:overflow-y-auto lg:rounded lg:shadow-search-dropdown-container',
            twoColumnLayout
              ? 'lg:min-w-[750px] lg:max-w-[750px] lg:grid-cols-2'
              : 'lg:min-w-[600px] lg:max-w-[600px]'
          )}
        >
          {!!products?.length ? (
            <Fragment>
              <SearchAutocompletePhrases
                searchInputValue={inputValue}
                phrases={phrases}
                setSearchInputValue={setInputValue}
              />
              <div>
                <SearchAutocompleteProducts
                  setSearchInputValue={setInputValue}
                  products={products}
                />
                <Link
                  href={buildSearchUrl('', inputValue)}
                  className="my-[10px] flex justify-center bg-none py-[13px] text-sm font-bold hover:text-accent-1 lg:col-start-2 lg:w-[217px]"
                  onClick={() => setInputValue('')}
                >
                  <Fragment>
                    <span className="mr-2">
                      {t('All search results', { ns: 'search' })} (
                      {totalProducts})
                    </span>
                    <ChevronLeft className="rotate-180 font-bold" />
                  </Fragment>
                </Link>
              </div>
            </Fragment>
          ) : (
            <div className="col-span-2 my-4 flex items-center justify-center text-sm">
              <span>
                {t('No results part text', { ns: 'search' })} "{inputValue}"
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(Searchbar)
