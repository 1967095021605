import { Dispatch, FC, Fragment, SetStateAction } from 'react'
import Link from '@components/ui/Link'
import { AutocompletePhrase } from '@lib/types/search'
import highlightSearchText from '@lib/utils/text/highlightSearchText'
import { useClientTranslation } from '@app/i18n/client'

type Props = {
  phrases?: AutocompletePhrase[]
  setSearchInputValue: Dispatch<SetStateAction<string>>
  searchInputValue?: string
}

const SearchAutocompletePhrases: FC<Props> = ({
  phrases,
  setSearchInputValue,
  searchInputValue,
}) => {
  const { t } = useClientTranslation('search')

  if (!phrases?.length) {
    return null
  }

  return (
    <section>
      <div className="mx-4 mb-3 mt-4 font-bold uppercase">
        {t('Search matching phrases')}
      </div>
      <hr className="mb-2" />
      <ul className="m-[10px] text-sm">
        {phrases?.map((phrase) => (
          <li key={`phrase-${phrase?.suggestion}`}>
            <Link
              href={`search?q=${phrase?.suggestion}` ?? ''}
              className="block rounded px-3 py-[10px] hover:bg-accent-5"
              onClick={() => setSearchInputValue('')}
            >
              <Fragment>
                {highlightSearchText(phrase?.suggestion, searchInputValue)}
              </Fragment>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default SearchAutocompletePhrases
