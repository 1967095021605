'use client'

import { FC } from 'react'
import AutocompleteProductCard from '@components/common/SearchAutocompleteProducts/AutocompleteProductCard'
import { AutocompleteProduct } from '@lib/types/search'
import { useClientTranslation } from '@app/i18n/client'

type Props = {
  products: AutocompleteProduct[]
  setSearchInputValue: (value: string) => void
}

const SearchAutocompleteProducts: FC<Props> = ({
  products,
  setSearchInputValue,
}) => {
  const { t } = useClientTranslation('search')

  return (
    <section>
      <div className="mx-4 mb-3 mt-4 font-bold uppercase">
        {t('Search matching products')}
      </div>
      <hr className="mb-2" />
      <div className="m-[10px]">
        {products?.map((product) => (
          <AutocompleteProductCard
            product={product}
            key={product.id}
            setSearchInputValue={setSearchInputValue}
          />
        ))}
      </div>
    </section>
  )
}

export default SearchAutocompleteProducts
